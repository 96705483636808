"use client";

import {
  LeadLoader,
  PageNotFoundLoader,
  DataNotFoundLoader,
  DefaultLoader,
  InitialLoader,
} from "@grudder/components/Common/Loaders";
import { createTheme, Loader, MantineProvider } from "@mantine/core";
import { ReactNode } from "react";

export default function Mantine({ children }: { children: ReactNode }) {
  const theme = createTheme({
    components: {
      Loader: Loader.extend({
        defaultProps: {
          loaders: {
            ...Loader.defaultLoaders,
            "gr-loader": DefaultLoader,
            "gr-lead-loader": LeadLoader,
            "gr-initial-loader": InitialLoader,
            "gr-page-not-found": PageNotFoundLoader,
            "gr-data-not-found": DataNotFoundLoader,
          },
        },
      }),
      Button: {
        defaultProps: {
          size: "md", // Default size is medium
        },
        styles: (_theme: any) => ({
          root: {
            height: "40px", // Setting the default height to 40px
            borderRadius: "8px",
          },
        }),
      },
      // Customizing TextInput component with default height
      Input: {
        defaultProps: {
          size: "md", // Default size is medium
        },
        styles: (_theme: any) => ({
          input: {
            height: "40px", // Setting the default height for inputs
            borderRadius: "8px",
          },
        }),
      },
      // Customizing TextInput component with default height
      TextInput: {
        defaultProps: {
          size: "md", // Default size is medium
        },
        styles: (_theme: any) => ({
          input: {
            height: "40px", // Setting the default height for inputs
            borderRadius: "8px",
          },
        }),
      },
      // You can add other inputs similarly (e.g., Select, PasswordInput)
      Select: {
        defaultProps: {
          size: "md",
        },
        styles: (_theme: any) => ({
          input: {
            height: "40px",
            borderRadius: "8px",
          },
        }),
      },
      PasswordInput: {
        defaultProps: {
          size: "md",
        },
        styles: (_theme: any) => ({
          input: {
            height: "40px",
            borderRadius: "8px",
          },
        }),
      },
    },
  });

  return <MantineProvider theme={theme}>{children}</MantineProvider>;
}
