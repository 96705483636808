import dynamic from "next/dynamic";

export const LeadLoader: any = dynamic(() => import("./Assets/Lead"), {
  ssr: false,
});
export const DefaultLoader: any = dynamic(
  () => import("./Assets/DefaultLoader"),
  { ssr: false }
);
export const PageNotFoundLoader: any = dynamic(
  () => import("./Assets/PageNotFound"),
  { ssr: false }
);
export const DataNotFoundLoader: any = dynamic(
  () => import("./Assets/DataNotFound"),
  { ssr: false }
);
export const InitialLoader: any = dynamic(
  () => import("./Assets/InitialLoader"),
  { ssr: false }
);
